export default async function (id: string) {
  const runtimeConfig = useRuntimeConfig()

  const getUserDetails = `mutation ($id: String){
    getUserDetailsById(input: {id: $id}) {
      avatar
      cap
      name
      posts {
        id
        slug
      }
    }
  }`

  const dataFetch = await useFetch(runtimeConfig.public.GQL_HOST, {
    method: 'post',
    body: {
      query: getUserDetails,
      variables: {
        id,
      },
    },
    transform: data => data?.data?.getUserDetailsById,
  })

  return dataFetch
};
