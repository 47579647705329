<script setup lang="ts">
interface Props {
  id: string
}

const props = defineProps<Props>()

const runtimeConfig = useRuntimeConfig()

const route = useRoute()

const { data, pending, error } = await getUserDetails(props.id)
const { data: dataPage } = await getPageIdBySlug(route.path)

const canEdit = computed(() => {
  // If user is admin, return true
  if (data?.value?.cap === 'administrator')
    return true

  // If user is not admin, check if user is author of post
  const ownPost = (data?.value?.posts || []).some(
    (post: unknown) => post.slug === route.path,
  )

  // If user is author of post, return true
  if (ownPost)
    return true

  // If user is not admin and not author of post, return false
  return false
})
</script>

<template>
  <div>
    <div
      v-if="pending"
      class="loading"
    >
      Loading...
    </div>
    <div
      v-if="error"
      class="error !-mt-8 !mx-0 !mb-0"
    >
      {{ error }}
    </div>
    <div
      v-if="!pending && !error"
      id="wpadminbar"
    >
      <div
        id="wp-toolbar"
        class="quicklinks"
        role="navigation"
        aria-label="Pasek narzędzi"
      >
        <ul
          id="wp-admin-bar-root-default"
          class="ab-top-menu"
        >
          <li id="wp-admin-bar-menu-toggle">
            <NuxtLink
              class="ab-item"
              to="#"
              aria-expanded="false"
            >
              <span
                class="ab-icon"
                aria-hidden="true"
              />
              <span class="screen-reader-text">Menu</span>
            </NuxtLink>
          </li>
          <li
            id="wp-admin-bar-site-name"
            class="menupop"
          >
            <NuxtLink
              class="ab-item"
              aria-haspopup="true"
              :to="`${runtimeConfig.public.apiHost}/wp-admin/index.php`"
            >
              Panel WP
            </NuxtLink>
          </li>
          <li
            id="wp-admin-bar-new-content"
            class="menupop"
          >
            <NuxtLink
              class="ab-item"
              aria-haspopup="true"
              :to="`${runtimeConfig.public.apiHost}/wp-admin/post-new.php`"
            >
              <span
                class="ab-icon"
                aria-hidden="true"
              />
              <span class="ab-label">Utwórz Wpis</span>
            </NuxtLink>
          </li>
          <li
            id="wp-admin-bar-new-content"
            class="menupop"
          >
            <NuxtLink
              class="ab-item"
              aria-haspopup="true"
              :to="`${runtimeConfig.public.apiHost}/wp-admin/post-new.php?post_type=page`"
            >
              <span
                class="ab-icon"
                aria-hidden="true"
              />
              <span class="ab-label">Utwórz Stronę</span>
            </NuxtLink>
          </li>
          <template v-if="canEdit">
            <li id="wp-admin-bar-edit">
              <NuxtLink
                class="ab-item"
                :to="`${runtimeConfig.public.apiHost}/wp-admin/post.php?post=${dataPage?.id}&action=edit`"
              >
                Edytuj stronę
              </NuxtLink>
            </li>
          </template>
        </ul>
        <ul
          id="wp-admin-bar-top-secondary"
          class="ab-top-secondary ab-top-menu"
        >
          <li
            id="wp-admin-bar-my-account"
            class="menupop with-avatar"
          >
            <NuxtLink
              class="ab-item"
              aria-haspopup="true"
              :to="`${runtimeConfig.public.apiHost}/wp-admin/profile.php`"
            >
              Witaj, <span class="display-name">{{ data.name }}</span>
              <img
                :src="data.avatar"
                width="26"
                height="26"
                alt="Avatar"
                class="avatar avatar-26 wp-user-avatar wp-user-avatar-26 photo"
              >
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url('~/assets/admin-styles.css');
</style>
