export default async function (slug: string) {
  const runtimeConfig = useRuntimeConfig()

  const getPageIdBySlug = `mutation ($slug: String){
    getPageIdBySlug(input: {slug: $slug}) {
      id
    }
  }`

  const dataFetch = await useFetch(runtimeConfig.public.GQL_HOST, {
    method: 'post',
    body: {
      query: getPageIdBySlug,
      variables: {
        slug,
      },
    },
    transform: data => data?.data?.getPageIdBySlug,
  })

  return dataFetch
}
